<template>
   <div class="animated fadeIn">
        <div class="flex">
            <div>
                <label class="col-form-label">Ebay ID:</label>
                <ar-select
                    :options="getOptions(new_values.platform_account_id)"
                    :val="new_values.platform_account_id"
                    disabled
                />
            </div>
            <div>
                <label class="col-form-label">Gross Merch Vol:</label>
                <ar-select
                    v-model="new_values.gross"
                    :options="getOptions(new_values.gross)"
                    :val="new_values.gross"
                >
                    Select Column
                </ar-select>
            </div>
            <div>
                <label class="col-form-label">No. of items sold:</label>
                <ar-select
                    v-model="new_values.items_sold"
                    :options="getOptions(new_values.items_sold)"
                    :val="new_values.items_sold"
                >
                    Select Column
                </ar-select>
            </div>
            <div>
                <label class="col-form-label">Available Items:</label>
                <ar-select
                    v-model="new_values.available_items"
                    :options="getOptions(new_values.available_items)"
                    :val="new_values.available_items"
                >
                    Select Column
                </ar-select>
            </div>
        </div>

        <div class="d-flex mt-5">
            <ar-button @click="apply()" class="mr-2">Update</ar-button>
            <ar-button @click="$emit('next')" :outlined="true">Cancel</ar-button>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import ArButton from '@/components/layouts/Button';
import ArSelect from '@/components/layouts/Select'

export default Vue.extend({
    name: 'ar-modal-import-ebay-list-column',
    components: {
        ArButton,
        ArSelect
    },
    props: {
        columns: {
            type: Array,
            required: true
        },
        value: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        new_values: {
            platform_account_id: null,
            gross: null,
            available_items: null,
            items_sold: null
        }
    }),
    beforeMount() {
        this.new_values = Object.assign({}, this.value);
    },
    methods: {
        apply() {
            this.$emit('input', this.new_values);
            this.$emit('next');
        },
        getOptions(include) {
            let selected = Object.values(this.new_values).filter(Boolean).filter(v => v !== include);
            return this.columns.filter(c => !selected.includes(c)).map(c => ({label: c, value: c}));
        }
    }
})
</script>