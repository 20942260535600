<template>
    <div
        class="animated fadeIn"
        :class="{'disabled-div': processing}"
    >
       <div class="d-flex justify-content-end mb-2">
           <ar-button @click="$emit('next')" class="mr-2" >Associate Columns</ar-button>
       </div>
       <div class="table-wrapper">
            <table class="table animated fadeIn">
                <thead>
                    <tr>
                        <th>
                            Doe
                        </th>
                        <th>
                            Seller
                        </th>
                        <th>
                            Ebay ID
                        </th>
                        <th>
                            Gross Merch Vol
                        </th>
                        <th>
                            No. of Items Sold
                        </th>
                        <th>
                            Available Items
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(listing, index) in listings" :key="index"
                        :class="{'matched': listing.doe_number}"
                    >
                        <td>
                            {{ listing.doe_number || 'No match' }}
                        </td>
                        <td>
                            {{ (listing.seller_name || 'No match') }}
                        </td>
                        <td>
                            {{ listing.platform_account_id }}
                        </td>
                        <td>
                            {{ listing.gross }}
                        </td>
                        <td>
                            {{ listing.items_sold }}
                        </td>
                        <td>
                            {{ listing.available_items }}
                        </td>
                    </tr>
                </tbody>
            </table>
       </div>

        <div class="d-flex mt-4">
            <ar-button @click="confirm()" class="mr-2">Confirm</ar-button>
            <ar-button @click="$emit('close')" :outlined="true">Cancel</ar-button>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import ArButton from '@/components/layouts/Button';
import axios from '../../../axiosMime'

export default Vue.extend({
    name: 'ar-modal-import-ebay-list-seller',
    components: {
        ArButton
    },
    props: {
        listings: {
            type: Array,
            required: true
        }
    },
    computed: {
        parent() {
            return this.$parent.$root.$children[0].$children.filter(child => child.$vnode.componentOptions.tag == "ar-master")[0];
        },
        matchedListings() {
            return this.listings.filter(l => l.id).map(l => ({
                id: l.id,
                case_id: l.case_id,
                listing_id: l.listing_id,
                seller_id: l.seller_id,
                platform_account_id: l.platform_account_id,
                gross: l.gross,
                available_items: l.available_items,
                items_sold: l.items_sold
            }));
        }
    },
    data: () => ({
        processing: false
    }),
    methods: {
        async confirm() {
            this.processing = true;
            this.parent.showSnackbar('Processing...', true);

            let headers = {...JSON.parse(JSON.parse(localStorage.getItem("lawfirm")).headers)};
            await axios.post(
                `${process.env.VUE_APP_URL}/case_listings/multi-update-sales`,
                {
                    listings: this.matchedListings
                },
                headers
            );

            this.parent.showSnackbar('Successfully imported!', false, 'success', 'fas fa-check-circle');
            this.processing = false;
            this.$emit('close');
            this.$emit('refresh');
        }
    }
})
</script>

<style scoped>
.table-wrapper {
    overflow: auto;
    height: 400px;
    border-top: 2px solid #dee2e6;
    border-bottom: 2px solid #dee2e6;
}

table {
    border-collapse: collapse;
    width: 100%;
}

th {
    background-color: white;
    z-index: 1;
    top: 0;
    position: sticky;
    box-shadow: inset 0px -2px 0px 0px #dee2e6;
    border-top: none !important;
}

.matched {
    background-color: #28a7452b;
    font-weight: bold;

}

.disabled-div {
    pointer-events: none;
    opacity: 0.4 !important;
}
</style>