<template>
    <div
        class="add-new-con"
        :class="{'disabledDiv': processing}"
    >
        <h5>Import Ebay Sales History</h5>
        <div class="mt-4 pt-2">
            <label class="d-block">Select File (.csv, .xls, .xlsx)</label>
            <ar-textfield
                a_type  ="file"
                a_class ="text-left d-inline-block text-truncate mb-0"
                :label  ="selectedFile ? selectedFile.name : 'Choose File'"
                @change ="attachFile"
                root-style="width: 305px;"
                accept=".csv,.xls,.xlsx"
            />
            <label class="d-block">Search by</label>
            <div class="d-flex mb-4">
                <ar-textfield
                    v-model="searchByTemp"
                    a_type  ="text"
                    a_class ="text-left mb-0 text-truncate"
                    root-style="width: 230px;" 
                    :disabled="disableSearchBy"
                />
                <ar-button v-if="disableSearchBy" @click="disableSearchBy = false" class="animated fadeIn ml-2" :outlined="true">
                    Edit
                </ar-button>
                <template v-else>
                    <ar-button @click="applySearchBy()" class="animated fadeIn ml-2">
                        Update
                    </ar-button>
                    <ar-button @click="discardSearchBy()" class="animated fadeIn ml-1" :outlined="true">
                        Cancel
                    </ar-button>
                </template>
            </div>
            <div>
                <ar-button class="mr-2" @click="upload()" :disabled="selectedFile === null || !Boolean(searchBy)">
                    {{
                        processing 
                            ? `Uploading (${progress}%)...`
                            : "Import"
                    }}
                </ar-button>
                <ar-button @click="$emit('close')" class="animated fadeIn mr-2" :outlined="true">
                    Close
                </ar-button>
            </div>
        </div>

        <img class="photo-modal" src="/static/svg/defendants/Modal_Photo.svg" alt="Woman carrying folder">
    </div>
</template>

<script>
import button from '../../../components/layouts/Button'
import axios from '../../../axiosMime'

export default {
    name: 'ar-modal-import-ebay-upload',
    components: { 
        'ar-button' : button,
        ArTextfield: () => import('../../../components/layouts/Textfield'),
    },
    props: {
        id: {
            type: String,
            default: 'import-ebay-sales-history'
        },
        caseID: {
            type: [Number, String],
            required: true
        }
    },
    data() {
        return {
            progress: 0,
            processing: false,
            selectedFile: null,
            searchBy: 'seller_id',
            searchByTemp: 'seller_id',
            disableSearchBy: true
        }
    },
    computed: {
        parent() {
            return this.$parent.$root.$children[0].$children.filter(child => child.$vnode.componentOptions.tag == "ar-master")[0];
        }
    },
    methods:{
        async upload() {
            this.processing = true;
            let headers = {...JSON.parse(JSON.parse(localStorage.getItem("lawfirm")).headers).headers};
            headers['Content-Type'] = 'multipart/form-data';
            let formData = new FormData();
            formData.append('file', this.selectedFile);
            formData.append('searchBy', this.searchBy);

            this.parent.showSnackbar('Uploading...', true);
            let result = await axios.post(
                `${process.env.VUE_APP_URL}/import/ebay-sales-history/${this.caseID}`,
                formData,
                {
                    headers,
                    onUploadProgress: function( progressEvent ) {
                        this.progress = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ));
                    }.bind(this)
                }
            );
            
            this.processing = false;
            this.parent.showSnackbar('Done', false, 'success', 'fas fa-check-circle');
            this.$emit('input', result.data);
            this.$emit('next');
        },
        attachFile(e) {
            let file = (e.target.files && e.target.files[0]) || { name: '' };//added fallback to redirect flow to if statement
            if (!['xls', 'xlsx', 'csv'].includes(file.name.split('.').pop())) {
                this.parent.showSnackbar('Please select a valid file (.xls, .xlsx, .csv)', false, 'invalid', 'fas fa-check-circle');
                this.selectedFile = null;
                return;
            }

            this.selectedFile = file;
        },
        applySearchBy() {
            this.searchBy = this.searchByTemp;
            this.disableSearchBy = true;
            this.$emit('searchByUpdate', this.searchBy);
        },
        discardSearchBy() {
            this.searchByTemp = this.searchBy;
            this.disableSearchBy = true;
        }
    }
}
</script>

<style scoped>
label {
    font-size:12px;
    font-weight: 500;
    text-align:left;
    margin-bottom: 6px;
}

.add-new-con {
    position: relative;
    text-align: left;
    padding: 20px 334px 10px 0;
}

.add-new-con h5 {
    font-weight: 500 !important;
    color: #515365;
    line-height: 24px;
    font-size: 24px;
    margin-bottom: 17px;
}

.add-new-con .photo-modal {
    position: absolute;
    right: -18px;
    top: 55%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.disabledDiv {
    pointer-events: none;
    opacity: 0.4;
}
</style>