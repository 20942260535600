<template>
    <ar-dialog
        id="vendor-move-modal"
        :has_button="false"
        :has_footer="false"
        custom_width="auto"
        :xxLarge="showPredefinedReasons"
        :large="!showPredefinedReasons"
    >
        <div class="row text-left justify-content-between">
            <div class="pr-4 border-right" :class="{'col-md-4': showPredefinedReasons, 'col-md-6': !showPredefinedReasons}">
                <div class="fadeIn animated">
                    <div class="mb-2">
                        <h5 class="d-inline-block ml-2 align-middle">
                            Move Selected data
                            <span class="d-block font-weight-normal mt-1">
                                You can select to move data to another status or to another case.
                            </span>
                        </h5>
                    </div>
                    <div class="a-modal-seller-info-item mt-4 pl-0 pr-3 generate-details">
                        <div class="custom-control custom-radio">
                            <input class="custom-control-input" v-model="mode" name="mode" type="radio" id="move-status" value="status" />
                            <label for="move-status" class="custom-control-label font-weight-medium" :class="{ 'text-primary': mode == 'status' }">Move to another Status</label>
                        </div>
                        <!-- :options="status_options" -->
                        <ar-select
                            v-model.number="selected_status"
                            id="select-move-status"
                            :disabled="mode == 'case'"
                            class="mb-3"
                            :options="statusOptions"
                            label="Select Status"
                        >
                            Select here
                        </ar-select>
                    </div>
                    <hr class="mt-1"/>
                    <div class="a-modal-seller-info-item mt-2 pl-0 pr-3 generate-details">
                        <div class="custom-control custom-radio">
                            <input class="custom-control-input" v-model="mode" type="radio" name="mode" id="move-case" value="case" />
                            <label for="move-case" class="custom-control-label font-weight-medium" :class="{ 'text-primary': mode == 'case' }">Move to another Case</label>
                        </div>
                        <ar-select
                            v-model="selected_case"
                            type="search"
                            id="select-move-case"
                            :disabled="mode == 'status'"
                            class="mb-3"
                            :options="case_options"
                            label="Select Case"
                        >
                            {{ case_options ? 'Select here' : 'Processing....' }}
                        </ar-select>
                    </div>
                </div>
            </div>
            <div class="pl-4 pr-4 col-md-4 w-100 border-right" v-show="showPredefinedReasons">
                <h5 class="d-inline-block ml-2 align-middle">
                    Select Reason(s)
                    <span class="d-block font-weight-normal mt-1">
                        A selection of predefined reasons are available below. Confirm by clicking on the 'Proceed' button.
                    </span>
                </h5>

                <div class="reasons-con">
                    <ar-reason-selection 
                        :reasons ="predefinedReasons" 
                        key      ="lawfirm-case-data-move-change-status-reasons"
                        :is-batch-modal="true"
                        vertical
                    />
                </div>
            </div>
            <div class="pl-4 w-100" :class="{'col-md-4': showPredefinedReasons, 'col-md-6': !showPredefinedReasons}">
                <h5 class="d-inline-block ml-2 text-truncate align-middle">Selected {{ listings.length }} Defendants<span class="d-block font-weight-normal mt-1">Defendant Information</span></h5>
                <hr class="mt-2"/>
                <div class="settlement-defendants-con right-con">
                    <template>
                        <div 
                            class="selected-settlement-defendants animated fadeIn" 
                            v-for="(listing, index) in listings"
                            :key="index"
                        >
                            <h6 class="ml-2">{{ listing.seller != null && listing.seller[0] ? listing.seller[0].name : '' }}</h6>
                            <label class="ml-2">DOE NUMBER {{ listing.case_listing != null && listing.case_listing[0] ? (listing.case_listing[0].doe_number || 0).toString() : '' }}<span> | {{ listing.id }}</span></label>
                            <table class="table table-borderless">
                                <tbody>
                                    <tr v-for="(info, iindex) in buildPaymentAccount(listing.case_seller_accounts.find(account => account.seller_account_id == listing.seller_account_id))" :key="iindex">
                                        <td class="header-label text-truncate" v-if="info.title"><img v-if="info.image" :src="info.image" alt="icon" class="mr-2">{{ info.title }}<span v-if="info.value">:</span></td>
                                        <td>
                                            <div class="d-flex justify-content-between">
                                                <b class="text-truncate" :class="{'d-block':info.title == 'Agreed Settlement'}">
                                                    {{ info.value }}
                                                </b>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </template>
                </div>
                <div class="button-con mt-3 float-right mb-4">
                    <ar-button 
                        class="mr-2"
                        data-dismiss="modal"
                        outlined
                    >
                        Cancel
                    </ar-button>
                    <ar-button
                        :disabled="valid_to_move !== true || processing"
                        @click="move()"
                    >
                        {{ processing == 'processing' ? 'Processing...' : 'Move Now' }}
                    </ar-button>
                </div>
            </div>
        </div>
    </ar-dialog>
</template>

<script>
    import GlobalComponents from '@/globalComponents.js'
    import button from '../../../components/layouts/Button'
    import select from '../../../components/layouts/Select'
    import dialog from '../../../components/layouts/Dialog'

    import axios from '../../../axiosMime'
    import { isEmpty } from '@/utils/common'
    import { ListingStatus } from '../../../utils/constants';
    import { getGuiSession } from '@/utils/session';

    import {mapActions,mapState} from 'vuex'
    export default {
        components: {
            ...GlobalComponents,
            'ar-button': button,
            'ar-select': select,
            'ar-dialog': dialog,
            ArReasonSelection: () => import('@/components/pages/verification/listing/ReasonsSelection')
        },
        props:{
            campaign_id: [Number, String],
            case_id: [Number, String],
            listings: [Array, Object],
            multipleStatusChange: {
                type: Function,
                default: function() {}
            },
            checkPotentialWhitelists: {
                type: Function,
                default: function() {}
            },
            checkListings: {
                type: Function,
                default: function() {}
            },
            setPredefinedReasons: {
                type: Function,
                default: function() {}
            }
        },
        data() {
            return {
                processing: false,
                mode: 'status',
                selected_case: null,
                selected_status: null,
                case_options: null,
                case_sellers: {},
                status_options: [{
                    label: 'Submitted',
                    value: '35'
                }, {
                    label: 'Accepted',
                    value: '32'
                }, {
                    label: 'For Review',
                    value: '3'
                }, {
                    label: 'For Monitoring',
                    value: '31'
                }, {
                    label: 'Has Potential',
                    value: '98'
                }, {
                    label: 'Rejected',
                    value: '33'
                }, {
                    label: 'Recycled',
                    value: '38'
                }],
                allPredefinedReasons: {},
                predefinedReasons: [],
                selected_reasons: [],
                fetchingReasons     : false,
                isLoading           : false,
                comment             : ''
            }
        },
        mounted() {
            $(document).on('show.bs.modal', '#vendor-move-modal', () => {
                this.mode = 'status'
                this.selected_status = '0'
                $('#select-move-status').val('0')
            })
            this.getCases();

            if (this.access_level == 7)
                this.status_options.push({ label: 'For Internal Review', value: '36' })

            const isGodzillaUser = () => JSON.parse(localStorage.getItem('lawfirm')).cxids.some(c => c.id === 723)

            if ((this.access_level == 7 || isGodzillaUser()) && this.$route.params.campaign_id == 723 )
                this.status_options.push({ label: 'Initial Review Completed', value: '37' })
        },
        watch: {
            valid_to_move() {
                if (this.valid_to_move == 'duplicate') {
                    this.$parent.$parent.showSnackbar('One or more seller already exist(s) in the selected case', false, 'invalid', 'fas fa-times-circle');
                }
            },
            selected_case(value) {
                if (value)
                    this.getCaseSellers()
            },
            selected_status(value) {
                // if (!value || [32, 35].includes(value))
                //     this.predefinedReasons = []

                if (value) {
                    /* if ([32].includes(value)) {
                        this.checkListings()
                        $('#listingsToBeAcceptedModal').on('hidden.bs.modal', () => {
                            $('#select-move-status').val('0')
                            this.selected_status = '0'
                        })
                    }
                    else {
                        this.getPredefinedReasons(value)
                    } */

                    // if (+value !== ListingStatus.Accepted) {
                    //     this.getPredefinedReasons(value);
                    // }

                    this.getPredefinedReasons(value);
                }
            }
        },
        computed: {
            showPredefinedReasons() {
                return this.mode === 'status' && this.predefinedReasons.length > 0
            },
            sellers() {
                return this.listings.filter(l => l.seller != null && l.seller[0] && l.seller[0].site_id).map(l => ({site_id: l.seller[0].site_id, platform_id: l.platform_id}));
            },
            valid_to_move() {
                if (this.mode == 'status') {
                    if (!this.selected_status || this.selected_status == "0")
                        return false;

                    // if ([31, /* '32', */ 33, 36, 98, 3].includes(this.selected_status) && (this.$store.state.checked_reasons.length === 0 && this.$store.state.checked_subreasons.length === 0 && isEmpty(this.$store.state.checked_other_reason)))
                    if ((this.$store.state.checked_reasons.length === 0 && this.$store.state.checked_subreasons.length === 0 && isEmpty(this.$store.state.checked_other_reason)))
                        return false
                }

                if (this.mode == 'case') {
                    if (!this.selected_case) {
                        return false;
                    }

                    if (!this.case_sellers[this.selected_case]) {
                        return 'processing';
                    }

                    if (this.case_sellers[this.selected_case] &&
                        this.sellers.find(s => this.case_sellers[this.selected_case].includes(`site-id-${s.site_id}-platform-id-${s.platform_id}`)) != null) {
                        return 'duplicate';
                    }
                }

                return true;
            },
            access_level(){
                return JSON.parse(localStorage.getItem('lawfirm')).access_level
            },
            statusOptions() {
                const statusOptions = [
                    { label: 'Submitted', value: ListingStatus.Submitted },
                    { label: 'Accepted', value: ListingStatus.Accepted },
                    { label: 'For Review', value: ListingStatus.Qualified },
                    { label: 'For Monitoring', value: ListingStatus.Monitoring },
                    { label: 'Has Potential', value: ListingStatus.HasPotential },
                    { label: 'Rejected', value: ListingStatus.Rejected },
                    { label: 'Recycled', value: ListingStatus.Recycled },
                    { label: 'For Internal Review', value: ListingStatus.ForInternalReview },
                    { label: 'Initial Review Completed', value: ListingStatus.InitialReviewCompleted }
                ];

                const showForInternalReviewOption = !this.isFeatureRestricted('cm-dropdown-move-data-for-internal-review');
                const showInitialReviewCompleted = !this.isFeatureRestricted('cm-dropdown-move-data-initial-review-completed');

                const filteredStatusOptions = statusOptions.filter(status => {
                    if (!showForInternalReviewOption) {
                        return status.value !== ListingStatus.ForInternalReview;
                    }

                    if (!showInitialReviewCompleted) {
                        return status.value !== ListingStatus.InitialReviewCompleted;
                    }

                    return status;
                });

                return filteredStatusOptions;
            },
            user() {
                return getGuiSession();
            }
        },
        methods: {
            getPredefinedReasons(id) {
                const headers = this.$session.get('lawfirm_headers')
                this.$store.dispatch('resetPredefinedReasonState')

                // this.batch_change_status = id
                const qflag = this.$parent.$parent.getQFlag(id)
                if(this.allPredefinedReasons[qflag]?.length > 0) {
                    this.predefinedReasons = this.allPredefinedReasons[qflag]
                    this.fetchingReasons   = true
                    return
                }

                this.isLoading = true
                this.$http.get(`/predefined_reasons/${id}?page_size=100`, headers)
                    .then(response => {
                        if(response.status === 200) {
                            const result = response.data;

                            /* this.predefinedReasons = result.data
                                .filter(reason => reason.status && !isReasonRestricted(reason))
                                .map(reason => ({
                                    id     : reason.id,
                                    type   : reason.type,
                                    reason : reason.sub_reasons.length
                                        ? {[reason.reason] : reason.sub_reasons
                                            .filter(sub => sub.status && !isReasonRestricted(sub))
                                            .map(sub => ({id: sub.id, reason: sub.text}))}
                                        : reason.reason
                                })) */

                                const reasons = result.data;

                                const role = this.user?.role ?? this.user.access_level;
                                const isReasonRestricted = ({ restricted_roles }) => restricted_roles !== null && restricted_roles.includes(role);

                                this.predefinedReasons = reasons.filter(reason => reason.status && !isReasonRestricted(reason))
                                    .map(item => {
                                        const { id, type, severity_level, sub_reasons, reason } = item;

                                        const getSubReasons = () => sub_reasons
                                            .filter(item => item.status && !isReasonRestricted(item))
                                            .map(item => ({ id: item.id, severity_level: item.severity_level, reason: item.text }));

                                        const mainReason = sub_reasons.length ? { [reason]: getSubReasons() } : reason;

                                        const WITH_SUBREASONS = 2;

                                        if (+type === WITH_SUBREASONS && mainReason[reason]?.length === 0) {
                                            return null;
                                        }

                                        return { id, type, severity_level, reason: mainReason };
                                    })
                                    .filter(item => item !== null);

                            this.allPredefinedReasons[qflag] = this.predefinedReasons
                            this.fetchingReasons = true
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        this.$parent.showSnackbar('Something went wrong. Please contact system admin.', false, 'success', 'fas fa-check-circle')
                    })
                    .finally(() => this.isLoading = false)
            },
            getCases() {
                let headers = JSON.parse(localStorage.getItem('headers'));
                headers.headers['Case-Only'] = 1;
                headers.headers['Where'] = `{"campaign_id": "${this.campaign_id}"}`;
                headers.headers['Sort'] = 'case_number,asc'
                return axios
                    .get(`${process.env.VUE_APP_URL}/cases?page_size=10000`, headers)
                    .then(response => {
                        this.case_options = response.data.data.filter(d => d.id != this.case_id).map(d => ({label: d.case_number, value: d.id}));
                        return true;
                    })
                    .catch(ex => {
                        console.log(ex);
                        this.$parent.$parent.showSnackbar('Something went wrong. Please contact system admin', false, 'invalid', 'fas fa-times-circle');
                        return false;
                    });
            },
            getCaseSellers() {
                if (this.case_sellers[this.selected_case]) {
                    return;
                }

                let selected_case = this.selected_case;
                let headers = JSON.parse(localStorage.getItem('headers'));
                headers.headers['Where'] = `{"case_id": "${selected_case}"}`;
                
                return axios
                    .get(`${process.env.VUE_APP_URL}/case_sellers?page_size=10000`, headers)
                    .then(response => {
                        this.$set(this.case_sellers, selected_case, response.data.data.filter(d => d.site_id).map(d => `site-id-${d.site_id}-platform-id-${d.platform_id}`));
                        return true;
                    })
                    .catch(ex => {
                        console.log(ex);
                        this.$parent.$parent.showSnackbar('Something went wrong. Please contact system admin', false, 'invalid', 'fas fa-times-circle');
                        return false;
                    });
            },
            move() {
                if (this.mode == 'status') {
                    this.moveToAnotherStatus();
                }
                else {
                    this.moveToAnotherCase();
                }
            },
            async moveToAnotherStatus() {
                const id = this.listings.map(l => l.id)
                const modal = 'vendor-move-modal'
                this.processing = true;
                this.$parent.$parent.showSnackbar('Processing...', true);

                //Check for potentials when accepting listing
                if(this.selected_status == 32) {
                    const callback = async () => {
                        const hasWhitelist = this.$store.state.potentialWhitelists.some(({potentials}) => {
                            return potentials.some(potential => potential.is_whitelist)
                        })

                        if(hasWhitelist) {
                            this.$parent.showSnackbar('Seller is whitelist', false, 'invalid', 'fas fa-times-circle')
                            return
                        }

                        const acknowledgement = {feature: 'status change', status: 1, notes: ''}
                        await this.$store.dispatch('acknowledgeWhitelist', acknowledgement)

                        $('#potential-whitelists').modal('hide')
                        $(`#${modal}`).modal('show')
                        
                        // this.hasAcknowledged = true
                        this.emit('acknowleged', true)
                        await this.multipleStatusChange(''+id, 32)
                        // this.hasAcknowledged = false
                        this.emit('acknowleged', false)
                    }

                    const hasPotentials = await this.checkPotentialWhitelists([id], modal, callback)
                    if(hasPotentials) return
                }

                // if([31, /* '32', */ 33, 36, 98, 3].includes(this.selected_status)) {
                    const hasNoReasons = this.$store.state.checked_reasons.length === 0
                        && this.$store.state.checked_subreasons.length === 0
                        && isEmpty(this.$store.state.checked_other_reason)

                    if(hasNoReasons)
                        return this.$parent.$parent.showSnackbar('Please select reason(s)', false, 'invalid', 'fas fa-times-circle')

                    if(!isEmpty(this.$store.state.checked_other_reason)) {
                        if(isEmpty(this.$store.state.other_reason_comment))
                            return this.$parent.$parent.showSnackbar('Please provide comment', false, 'invalid', 'fas fa-times-circle')
                    }
                // }

                this.$parent.$parent.showSnackbar('Updating status.. Please wait..', true)
                this.$emit('status-changing', true)
                // this.status_changing     = true
                this.$emit('already-worked-lids', '')
                // this.already_worked_lids = ''

                if(this.selected_status == '0') {
                    this.$parent.$parent.showSnackbar(`Status should be set.`, false, 'invalid', 'fas fa-exclamation-circle')
                    this.$emit('status-changing', false)
                    // this.status_changing = false
                    return
                }
                
                return axios
                    .patch(`${process.env.VUE_APP_URL}/cases/remove-listings/${this.case_id}`,
                        {
                            new_status: this.selected_status,
                            listings: this.listings.map(l => l.id),
                            reasons: this.setPredefinedReasons()
                        },
                        JSON.parse(localStorage.getItem('headers')))
                    .then(response => {
                        this.$parent.$parent.showSnackbar('Moved successfully', false, 'success', 'fas fa-check-circle');
                        this.selected_status = '0'
                        this.$emit('alter');
                        $('#vendor-move-modal').modal('hide');
                        this.processing = false;
                        return true;
                    })
                    .catch(ex => {
                        this.processing = false;
                        console.log(ex);
                        this.$parent.$parent.showSnackbar('Something went wrong. Please contact system admin', false, 'invalid', 'fas fa-times-circle');
                        return false;
                    });
            },
            moveToAnotherCase() {
                this.processing = true;
                this.$parent.$parent.showSnackbar('Processing...', true);
                
                return axios
                    .patch(`${process.env.VUE_APP_URL}/listings/switch-case/${this.case_id}`,
                        {
                            new_id: this.selected_case,
                            listings: this.listings.map(l => l.id)
                        },
                        JSON.parse(localStorage.getItem('headers')))
                    .then(response => {
                        this.$parent.$parent.showSnackbar('Moved successfully', false, 'success', 'fas fa-check-circle');
                        this.$emit('alter');
                        $('#vendor-move-modal').modal('hide');
                        this.processing = false;
                        return true;
                    })
                    .catch(ex => {
                        console.log(ex);
                        this.$parent.$parent.showSnackbar('Something went wrong. Please contact system admin', false, 'invalid', 'fas fa-times-circle');
                        this.processing = false;
                        return false;
                    });
            },
            buildPaymentAccount(account) {
                if (account == null) {
                    return [];
                }

                let references = {
                    "name_email": { image: "/static/svg/defendants/Details_Account.svg", title: "Account Name"},
                    "type": {image: "/static/svg/defendants/Details_Account.svg", title: "Account Type"},
                    "number": {image: "/static/svg/defendants/Details_Account.svg", title: "Account Number"},
                    "amount_settled": {image: "/static/svg/Case_Settled.svg", title: "Agreed Settlement"},
                    "amount_frozen": {image: "/static/svg/Case_Frozen.svg", title: "Amount Frozen"}
                }
                let result = {
                    "name_email": {},
                    "type": {},
                    "number": {},
                    "amount_frozen": {},
                    "amount_settled": {}
                };

                for (let key of Object.keys(result)) {
                    result[key] = {
                        image: references[key].image,
                        title: references[key].title,
                        value: account[key]
                    };
                }

                return Object.values(result);
            }
        }
    }
</script>

<style scoped>
    #vendor-move-modal .campaign-img {
        height: 40px;
        width: 40px;
    }

    #vendor-move-modal h5 {
        font-size: 16px;
        color: #515365;
        width: 275px;
    }

    #vendor-move-modal h5 span {
        font-size: 12px;
    }

    #vendor-move-modal p {
        font-size: 12px;
        color: #515365;
    }

    #vendor-move-modal .left-con,
    #vendor-move-modal .right-con {
        max-width: 400px;
        height: 330px;
        overflow-y: auto;
    }

    #vendor-move-modal .left-con::-webkit-scrollbar,
    #vendor-move-modal .right-con::-webkit-scrollbar {
        width: 5px !important;
    }

    #vendor-move-modal .settlement-defendants-con {
        text-align: left;
        height: 375px;
    }

    #vendor-move-modal .selected-settlement-defendants {
        margin-bottom: 28px;
    }

    #vendor-move-modal .selected-settlement-defendants h6 {
        font-weight: 500;
        margin-bottom: 3px;
        font-size: 15px;
        color: #203154;
        letter-spacing: 0.13px;
    }

    #vendor-move-modal .selected-settlement-defendants label {
        font-weight: 600;
        font-size: 12px;
        color:#2D599E;
        letter-spacing: 0.4px;
        margin-bottom: 10px;
    }

    #vendor-move-modal .selected-settlement-defendants label span {
        font-weight: 400;
        font-size: 12px;
        color:#3E88DA;
    }

    #vendor-move-modal .table tbody tr td {
        font-size: 12px; 
        font-weight: 600;
        color: #141517;
        letter-spacing: 0.4px;
        padding-top: 5px;
        padding-bottom: 4px;
    }

    #vendor-move-modal .table tbody tr td b {
        display: block;
        width: 127px;
    }

    #vendor-move-modal .table tbody tr td.header-label {
        font-weight: 400;
        color: #515365;
    }

    #vendor-move-modal .table tbody tr td.header-label img {
        width: 12px;
        height: 12px;
    }

    .reasons-con {
        max-height: 453px;
        overflow-y: auto;
    }
</style>
