/**
 * Modal for showing detected whitelisted and potential whitelisted sellers
 * before tagging 
 */
<template>
    <div>
        <ar-dialog
            :id              ="id"
            mc-class        ="p-0"
            mb-class        ="p-0"
            custom_width    ="1200px"
            custom_maxwidth ="100%"
            :has_footer     ="false"
            :has_button     ="false"
            large
        >
            <div>
                <div class="row display-text m-0">
                    <div class="col-md-4 text-left btn-sellers-bg p-5">
                        <div class="d-flex align-items-top mb-5">
                            <img :src="campaign.img" class="mr-3" style="width: 50px; height: 50px;">
                            <div>
                                <h5>{{ campaign.name }}</h5>
                                <p>Selected {{ sellers.length }} Listings</p>
                            </div>
                        </div>

                        <div class="d-flex flex-column justify-content-between" style="height: 254px;">
                            <ar-button class="btn-sellers mb-3 text-left" :class="{'active': activeIndex === 'sellers'}" outlined @click="activeIndex = 'sellers'">
                                <img :src="`/static/svg/Potential_${activeIndex === 'sellers' ? 'White' : 'Blue'}.svg`" class="mr-3" />
                                <span :title="`${totalNonWhiteLists} Non Whitelist Sellers`" class="badge badge-primary rounded-circle position-absolute" style="padding: 4px 5px 2px 5px; right: -5px; top: -5px; background-color: #1B7E42"> {{totalNonWhiteLists}} </span>
                                Non Whitelist Sellers
                            </ar-button>
                            <div class="d-flex">
                                <ar-button class="btn-sellers mb-3 text-left w-100" :class="{'active': activeIndex === 'whitelists'}" outlined @click="activeIndex = 'whitelists'">
                                    <img :src="`/static/svg/Potential_${activeIndex === 'whitelists' ? 'White' : 'Blue'}.svg`" class="mr-3" />
                                    <span :title="`${whitelists.length} Unremoved Whitelist Sellers`" class="badge badge-primary rounded-circle position-absolute" style="padding: 4px 5px 2px 5px; right: -5px; top: -5px; background-color: #FF031D"> {{whitelists.length}} </span>
                                    Whitelist Sellers
                                </ar-button>
                                
                            </div>
                            
                            <ar-button class="btn-sellers mb-3 text-left" :class="{'active': activeIndex === 'potentials'}" outlined @click="activeIndex = 'potentials'">
                                <img :src="`/static/svg/Potential_${activeIndex === 'potentials' ? 'White' : 'Blue'}.svg`" class="mr-3" />
                                <span :title="`${unacknowledgedPotentials.length} Unaccepted potential whitelist sellers`" class="badge badge-primary rounded-circle position-absolute" style="padding: 4px 5px 2px 5px; right: -5px; top: -5px; background-color: #FF8800"> {{unacknowledgedPotentials.length}} </span>
                                Potential Whitelist Sellers
                            </ar-button>

                            <ar-button class="btn-sellers mb-3 text-left" :class="{'active': activeIndex === 'duplicates'}" outlined @click="activeIndex = 'duplicates'">
                                <img :src="`/static/svg/Potential_${activeIndex === 'duplicates' ? 'White' : 'Blue'}.svg`" class="mr-3" />
                                <span :title="`${duplicates.length} duplicate sellers`" class="badge badge-secondary rounded-circle position-absolute" style="padding: 4px 5px 2px 5px; right: -5px; top: -5px"> {{duplicates.length}} </span>
                                Duplicate Sellers
                            </ar-button>
                        </div>

                        <div v-if="activeIndex === 'potentials' && activeListings.length">
                            <p>Note: (optional)</p>
                            <textarea
                                v-model="potentialNote" 
                                placeholder="Leave a note for acknowledging potential(s)." 
                                class="animated fadeIn potentials-note p-2 w-100" 
                                style="resize: none;"
                                :disabled="isProceeding"
                            />
                        </div>
                    </div>
                    <div class="col-md-8 text-left p-5">
                        <h5 style="color: #1BC442"><slot name="header"></slot></h5>
                        <span v-if="activeIndex === 'sellers'" style="color: #1Bc442">
                            <i class="fas fa-info-circle mr-1"></i> Detected {{ activeListings.length }} Non Whitelist Sellers
                        </span>
                        <span v-else-if="activeIndex === 'whitelists'" style="color: #FF031D">
                            <i class="fas fa-info-circle mr-1"></i> Detected {{ activeListings.length }} Whitelist Sellers
                        </span>
                        <span v-else-if="activeIndex === 'potentials'" style="color: #FF8800">
                            <i class="fas fa-info-circle mr-1"></i> Detected {{ activeListings.length }} Potential Whitelist Sellers
                        </span>
                        <span v-else style="color: #6c757d">
                            <i class="fas fa-info-circle mr-1"></i> Detected {{ activeListings.length }} Duplicate Sellers (these will be automatically excluded)
                        </span>
                        <hr>
                        <div :class="{'invinsible': isLoading}">
                            <div class="d-flex justify-content-between mb-2">
                                <ar-button 
                                    class="animated fadeIn" 
                                    :class="{'invisible': activeIndex !== 'potentials' || (activeIndex === 'potentials' && !activeListings.length)}"
                                    @click="togglePotential(null, 'all')"
                                    outlined
                                    :disabled="isProceeding"
                                >
                                    {{ isAllPotentialAccepted ? 'Unaccept All' : 'Accept All' }}
                                </ar-button>
                                <ar-button
                                    :class="`btn-invalid animated fadeIn mr-3 ${hideRemoveAllBtn}`"
                                    @click="removeWhitelists"
                                    :disabled="isProceeding"
                                >
                                    Remove All
                                </ar-button>
                            </div>
                            <div :id="`${id}-duplicates-accordion`" class="d-flex flex-column overflow-auto text-left" style="height: 450px;">
                                <template
                                    v-for="listing in activeListings"
                                >
                                    <div 
                                        v-if="activeIndex !== 'duplicates'"
                                        :key="listing.id" 
                                        class="h-auto"
                                        style="width: 98%;"
                                    >
                                        <div class="sellers d-flex m-0 justify-content-between align-items-center" :class="{'whitelist-sellers': activeIndex === 'whitelists', 'potential-sellers' : activeIndex === 'potentials', 'accepted': listing.isAcknowledged}">
                                            <div :class="{'invisible': activeIndex !== 'potentials'}" class="ml-2">
                                                <div class="custom-control custom-checkbox a-checkbox">
                                                    <input 
                                                        type="checkbox" 
                                                        :id="listing.id" 
                                                        class="custom-control-input"
                                                        v-model="listing.isAcknowledged"
                                                        @click="togglePotential(listing.id)"
                                                    >
                                                    <label :for="listing.id" class="custom-control-label"></label>
                                                </div>
                                            </div>
                                            <div class="w-100 d-flex flex-column" >
                                                <p class="m-1 row text-left">
                                                    <span class="col"> {{ listing.name }} </span>
                                                </p>
                                                <p class="m-1 row text-left">
                                                    <span class="col column-label">Listing ID: <span class="column-value">{{ listing.id }}</span> </span>
                                                    <span class="col column-label">Site ID: <span class="column-value">{{ listing.site_id }}</span> </span>
                                                </p>
                                            </div>
                                            <div v-if="isWhitelistOrPotential" class="cursor-pointer">
                                                <div v-if="!listing.isAcknowledged || listing.isWhitelist" class="m-2 text-red" @click="removeListing(listing)">
                                                    <img src="/static/svg/Remove_List.svg">
                                                </div>
                                            </div>
                                        </div>
                                        <hr class="m-2">
                                    </div>
                                    <div
                                        v-else
                                        class="h-auto card mb-0"
                                        :key="listing.id"
                                        style="width: 98%;"
                                    >
                                        <div
                                            :id="`${id}-listing-${listing.id}`"
                                            data-toggle="collapse"
                                            :data-target="`#${id}-collapse-${listing.id}`"
                                            :aria-controls="`#${id}-collapse-${listing.id}`"
                                            class="card-header collapsed sellers d-flex m-0 justify-content-between align-items-center"
                                        >
                                            <div class="w-100 d-flex flex-column" >
                                                <p class="m-1 row text-left">
                                                    <span class="col"> {{ listing.name }} </span>
                                                </p>
                                                <p class="m-1 row text-left">
                                                    <span class="col column-label">Listing ID: <span class="column-value">{{ listing.id }}</span> </span>
                                                    <span class="col column-label">Site ID: <span class="column-value">{{ listing.site_id }}</span> </span>
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            :id="`${id}-collapse-${listing.id}`"
                                            class="slow p-0 collapse"
                                            :aria-labelledby="`${id}-heading-${listing.id}`"
                                            :data-parent="`#${id}-duplicates-accordion`"
                                        >
                                            <div class="card-body p-0">
                                                <div class="row">
                                                    <div v-for="duplicate in listing.duplicates" class="col-md-4" :key="duplicate.id">
                                                        <div class="a-modal-seller-info-item p-0 mt-2 ml-2">
                                                            <h5 class="text-left">
                                                                <span v-if="duplicate.case_number !== null" class="text-truncate"
                                                                    :title="duplicate.case_number">Case No. {{ duplicate.case_number
                                                                    }}</span>
                                                                <span class="text-truncate" :title="duplicate.name">{{ duplicate.name
                                                                }}</span>
                                                                <span class="text-primary cursor-pointer" :title="duplicate.id">{{
                                                                        duplicate.id
                                                                }}</span>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <div v-show="!activeListings.length">
                                    No sellers found.
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-end">
                            <ar-button class="m-1" outlined data-dismiss="modal" :disabled="isProceeding">Cancel</ar-button>
                            <ar-button class="m-1" @click="submitListings" :disabled="isProceeding || isLoading">{{ activeIndex === 'duplicates' && activeListings.length ? "Remove duplicates and Proceed" : "Proceed" }}</ar-button>
                            <ar-button class="m-1" @click="submitListings(false)" v-if="activeIndex === 'duplicates' && activeListings.length" :disabled="isProceeding || isLoading">Proceed Anyway</ar-button>
                        </div>
                    </div>
                </div>
            </div>       
        </ar-dialog>    
    </div>
</template>

<script>
import { getImage, showSnackbar } from "@/utils/common"
export default {
    name: 'whitelisted-modal',
    components: {
        ...vm.$layouts.getLayouts([
            'ArDialog',
            'ArTextfield',
            'ArButton',
            'ArCheckboxes'
        ])
    },
    props: {
        listings : {
            type: Array,
            default: () => ([])
        },
        id: {
            type: String,
            default: "whitelistedModal"
        },
        acknowledgementFeature: String,
        campaign: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            sellers: [],
            isLoading: true,
            /**
             * @type {'sellers' | 'potentials' | 'whitelists' | 'duplicates'}
             */
            activeIndex: 'sellers',
            getImage,
            potentialNote: '',
            isProceeding: false
        }
    },
    computed: {
        token() {
            return this.$session.get('lawfirm_headers')
        },
        subHeading() {
            return 'This is a sub heading'
        },
        displayableSellers() {
            const getSellers = (key = 'seller') => this.sellers.filter(
                    (listing) => key !== 'seller' ? listing[key] : !listing.isWhitelist && !listing.hasPotentials
                )

            const whitelists = getSellers('isWhitelist')
            const potentials = getSellers('hasPotentials')
            const duplicates = getSellers('hasDuplicates');
            const sellers = getSellers()

            return { whitelists, potentials, sellers, duplicates }
        },
        activeListings() {
            const index = this.activeIndex

            return this.displayableSellers[index]
        },
        isWhitelistOrPotential() {
            return ['potentials', 'whitelists'].includes(this.activeIndex)
        },
        unProceedable() {
            return this.whitelists.length > 0
                || this.unacknowledgedPotentials.length > 0
        },
        duplicates() {
            return this.displayableSellers['duplicates'];
        },
        whitelists() {
            return this.sellers.filter(seller => seller.isWhitelist)
        },
        nonWhiteList() {
            return this.sellers.filter(seller => !seller.hasPotentials && !seller.isAcknowledged && !seller.hasDuplicates)
        },
        unacknowledgedPotentials() {
            return this.sellers.filter(seller => seller.hasPotentials && !seller.isAcknowledged)
        },
        totalNonWhiteLists() {
            return this.sellers.filter(seller => !seller.isWhitelist && !seller.hasPotentials && !seller.hasDuplicates).length;
        },
        isAllPotentialAccepted() {
            return this.sellers.filter(seller => seller.hasPotentials).every(seller => seller.isAcknowledged)
        },
        activeSubHeadingColor() {
            return this.activeIndex === 'whitelists' ? '#FF031D' : '#FF8800'
        },
        hideRemoveAllBtn() {
            const index = this.activeIndex
            if(index === 'potentials' && this.isAllPotentialAccepted)
                return 'invisible'
            
            if(index === 'whitelists' && !this.whitelists.length)
                return 'invisible'

            if(index === 'sellers')
                return 'invisible'
            
            if (index === 'duplicates')
                return 'invisible'

            return ''
        }
    },
    methods: {
        togglePotential(listingId, mode = 'single') {
            if(mode === 'all') {
                this.sellers = this.sellers.map(seller => {
                    seller.isAcknowledged = !this.isAllPotentialAccepted
                    return seller
                })
                return
            }
            this.sellers = this.sellers.map(seller => {
                if(seller.id === listingId) seller.isAcknowledged = !seller.isAcknowledged
                return seller
            })
        },
        setPotentialsToBeAcknowledged(listing = [], mode = 'single') {
            if(mode === 'all') {
                return ''
            }

            this.sellers = this.sellers.map(seller => {
                if(seller.id === listing.id) 
                    seller.isAcknowledged = true
                    
                return seller
            })
        },
        removeListing(listing) {
            this.sellers = this.sellers.filter(seller => seller.id !== listing.id)
        },
        removeWhitelists() {
            const index = this.activeIndex
            if(index === 'whitelists')
                this.sellers = this.sellers.filter(seller => !seller.isWhitelist)

            if(index === 'potentials')
                this.sellers = this.sellers.filter(seller => !seller.hasPotentials)
        },
        async acknowledgeFully(sellers) {
            const acknowledges = sellers.map(seller => ({
                feature: this.acknowledgementFeature,
                status: 1,
                notes: `${this.potentialNote ?? 'Not Set'}. Seller: ${seller.name}`
            }))

            return this.$http.post('/api/acknowledgements/bulk', { acknowledges }, this.token)
        },
        async submitListings(removeDuplicates = true) {
            this.isProceeding = true
            if (removeDuplicates)
                this.sellers = this.sellers.filter(listing => !listing.hasDuplicates)
            
            if(this.whitelists.length) {
                showSnackbar('Please remove whitelisted sellers', false, 'invalid', 'fas fa-info-circle')
                this.isProceeding = false
                return
            }

            if(this.unacknowledgedPotentials.length) {
                showSnackbar('Please accept or remove potential whitelist sellers', false, 'invalid', 'fas fa-info-circle')
                this.isProceeding = false
                return
            }

            if(!this.displayableSellers["sellers"].length) {
                showSnackbar('No listings to be accepted', false, 'invalid', 'fas fa-info-circle')
                this.isProceeding = false
                return
            }

            //Get listing with potential whitelist to be acknowledged
            const toBeAcknowledged = this.sellers.filter(seller => seller.hasPotentials && seller.isAcknowledged)
            const isAcknowledged = await this.acknowledgeFully(toBeAcknowledged)

            if(!isAcknowledged) {
              this.isProceeding = false  
              return
            }

            this.$emit("proceed", { sellers: [...this.displayableSellers['potentials'].filter(s => s.isAcknowledged), ...this.displayableSellers["sellers"]], allowDuplicateBypass: !removeDuplicates });
        }
    },
    watch: {
        listings(listings) {
            this.isLoading = true
            if(listings.length) {
                this.isLoading = false
                this.isProceeding = false
            }

            this.sellers = listings.map(listing => {
                listing.isAcknowledged = false
                return listing
            })
        }
    }
}
</script>

<style scoped>
    .sellers {
        border-radius: 4px;
        opacity: 1;
    }
    .whitelist-sellers {
        background: #FF031D29 0% 0% no-repeat padding-box;
    }

    .potential-sellers {
        background: #FF8E0D70 0% 0% no-repeat padding-box;
    }

    .potential-sellers.accepted {
        background: #3E88DA45 0% 0% no-repeat padding-box;
    }

    .duplicate-sellers {
        background: #6c757d66 0% 0% no-repeat padding-box;
    }

    .display-text {
        font: normal normal normal 14px/19px Roboto;
        letter-spacing: 0.24px;
        color: #515365;
        opacity: 1;
    }

    .column-label {
        font: normal normal medium 12px/16px Roboto;
        letter-spacing: 0.21px;
        color: #2D599E;
    }

    .column-value {
        font: italic normal normal 12px/16px Roboto;
        letter-spacing: 0.21px;
        color: #2D599E;
    }

    .btn-sellers {
        height: 48px;
        border-radius: 8px;
        opacity: 1;
    }

    .btn-sellers.active {
        background: #3E88DA 0% 0% no-repeat padding-box;
        font: normal normal medium 14px/19px Roboto;
        letter-spacing: 0.24px;
        color: #FFFFFF;
        opacity: 1;
    }

    .btn-sellers-bg {
        background: #3E88DA12 0% 0% no-repeat padding-box;
        border-radius: 10px 0px 0px 10px;
        opacity: 1;
    }

    .potentials-note {
        width: 271px;
        height: 150px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #DEDEDF;
        border-radius: 4px;
        opacity: 1;
    }

</style>