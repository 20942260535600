<template>
    <ar-dialog 
            id            ="compile-frozen-modal"
            custom_width  ="800px"
            large
            :has_button   ="false"
            :has_footer   ="false">
        <template>
            <div class="add-new-con" :class="{'pb-5': !response.result}">
                <h5>Frozen Accounts Compiler</h5>
                <!-- <ar-banner
                    :has_btn="false"
                    heading  ="Note:"
                    class="animated fadeIn faster mt-4"
                    style="color: #FF5722; background-color: #F1E5E2; border-color:#FF5722;"
                    text     ="Before uploading, make sure to select the zip file that isn't password protected"
                /> -->
                <!-- <div v-if="!modal">
                    <ar-select 
                        label="Campaign"
                        :options="campaigns"
                        v-model="current_campaign"
                        @change="case_id_ = 0"
                    >
                        {{ campaigns ? 'Select here' : 'Loading...' }}
                    </ar-select>
                    <ar-select 
                        label="Case Number"
                        :options="cases !== null & current_campaign ? cases[current_campaign] : null"
                        :val="case_id_"
                        v-model="case_id_"
                    >
                        {{ cases ? 'Select here' : 'Loading...' }}
                    </ar-select>
                </div> -->
                <div class="mt-4 pt-2">
                    <template v-if="response.result">
                        <div class="animated fadeIn">
                            <b class="d-block" style="color: #3a9c51; font-size: 18px;">Compilation Complete</b>
                            <p class="pr-5" style="font-size: 16px;">You can now download the Excel file.</p>
                            <!-- <label class="d-block mt-2" style="font-size:12px; font-weight: 500; text-align:left; margin-bottom: 6px;">Download</label> -->
                            <ar-button @click="downloadExcel()" class="btn-success">Download Excel file</ar-button>
                            <ar-button class="ml-2" a_class="btn-outline-invalid" v-if="response.has_error" @click="downloadErrorsLog()" :outlined="true">Error Log(s)</ar-button>
                        </div>
                        <hr/>
                    </template>

                    <b class="d-block" style="color: #2D599E; font-size: 18px;" v-if="response.result">Or Upload New file</b> 
                    <label class="d-block" style="font-size:12px; font-weight: 500; text-align:left; margin-bottom: 6px;" :class="{'mt-2': response.result}">Upload Source File</label>
                    <ar-textfield
                        a_type  ="file"
                        a_class ="text-left d-inline-block text-truncate"
                        id="frozenAccountSourceInput"
                        :label  ="files && files.length >= 1 ? (files.length > 1 ? `${files.length} files` : files[0].name ) : 'Choose File'"
                        @change ="attachFile"
                        :root-style="response.result ? `width: 252px;` : `width:305px;`" 
                        multiple
                        accept=".csv,.xls,.xlsx,.zip"
                    />
                    <ar-button class="animated fadeIn ml-3 align-top" data-dismiss="modal" v-if="response.result" :outlined="true">Close</ar-button>

                    <template v-if="!response.result">
                        <b class="d-block" style="color: #2D599E;">Note before uploading,</b> 
                        <p class="pr-5" style="font-size: 16px;">make sure to select zip file with no password protection.</p>
                        <div>
                            <ar-button class="mr-2" @click="compile()" :disabled="disable_compile_btn">{{processing ? (progress >= 100 ? 'Compiling..' : `Uploading (${progress}%)`) : "Compile"}}</ar-button>
                            <ar-button class="animated fadeIn mr-2" data-dismiss="modal" :outlined="true">Close</ar-button>
                            <!-- <router-link v-if="modal" :to="{name: 'lawfirmfrozenaccountcompiler'}" target="_blank">Standalone Page</router-link> -->
                        </div>
                    </template>
                </div>

                <img class="photo-modal" src="/static/svg/defendants/Modal_Photo.svg" alt="Woman carrying folder">
            </div>
        </template>
    </ar-dialog>
</template>

<script>
import button from '../../../components/layouts/Button'
import banner from '../../layouts/Banner'
import axios from '../../../axiosMime'
export default {
    name: 'ar-frozen-account-compiler',
    components: { 
        'ar-button' : button,
        // 'ar-banner' : banner,
        // ArSelect: () => import('../../layouts/Select'),
        ArDialog: () => import('../../layouts/Dialog'),
        ArTextfield: () => import('../../../components/layouts/Textfield')
    },
    data() {
        return {
            progress: 0,
            processing: false,
            files: null,
            response: {result: null, error: null, has_error: false, has_unprocessed_zip: false},
            campaigns: null,
            cases: null,
            current_campaign: null,
            case_id_: null,
        }
    },
    props: {
        case_id: null,
        modal: {
            type: Boolean,
            default: false
        }
    },
    beforeCreate(){
        let ver = JSON.parse(localStorage.getItem("lawfirm"))
        if(ver.token == null || typeof ver.token === "undefined" || ver.token == ""){
            this.$router.push('/lawfirm')
        }
    },
    async beforeMount() {
        if (!this.modal) {
            try {
                await this.getCampaigns();
                await this.getCases();
            }
            catch(ex) {
                console.log(ex);
                this.showSnackbar('Something went wrong. Please contact system admin', false, 'invalid', 'fas fa-check-circle');
            }
        }
    },
    mounted() {
        $(document).on('show.bs.modal', '#compile-frozen-modal', this.resetUserInput);
        let modal = this.modal;
        setTimeout(function(){
            if (!modal) {
                $('#compile-frozen-modal').modal({backdrop: 'static', keyboard: false})
            }
        }, 300);
    },
    computed: {
        disable_compile_btn() {
            return this.processing ||
                !this.current_case ||
                this.current_case == 0 ||
                !this.files ||
                this.files.length === 0;
        },
        current_case() {
            if (this.modal) {
                return this.case_id;
            }
            else {
                return this.case_id_;
            }
        }
    },
    methods:{
        getCampaigns() {
            this.campaigns = null;
            let header = JSON.parse(JSON.parse(localStorage.getItem("lawfirm")).headers);
            header.headers.Sort = 'name,asc';
            return axios.get(
                `${process.env.VUE_APP_URL}/lawfirm_campaigns/main?page_size=10000`, header)
                .then(response => {
                    this.campaigns = response.data.data.map(r => ({label: r.name, value: r.id}));
                })
                .catch(ex => {
                    this.campaigns = [];
                    throw ex;
                });
        },
        getCases() {
            this.cases = null;
            let header = JSON.parse(JSON.parse(localStorage.getItem("lawfirm")).headers);
            header.headers['Case-Only'] = 1;
            return axios.get(
                `${process.env.VUE_APP_URL}/cases?page_size=10000`, header)
                .then(response => {
                    this.cases = [];
                    response.data.data.forEach(r => {
                        if (!(r.campaign_id in this.cases)) {
                            this.cases[r.campaign_id] = [];
                        }
                        this.cases[r.campaign_id].push({label: r.case_number, value: r.id});
                    });
                })
                .catch(ex => {
                    this.cases = [];
                    throw ex;
                });
        },
        resetUserInput() {
            this.files = null;
            this.response.result = null;
            document.getElementById('frozenAccountSourceInput').value = '';
        },
        attachFile(e) {
            console.log(e)
            for( var i = 0; i < e.target.files.length; i++ ){
                let file = e.target.files[i];
                if (!['xls', 'xlsx', 'csv', 'zip'].includes(file.name.split('.').pop())) {
                    this.showSnackbar('Please select a valid file (.xls, .xlsx, .csv, .zip)', false, 'invalid', 'fas fa-check-circle');
                    return;
                }
            }

            this.response.result = null;
            this.files = e.target.files;
        },
        compile() {
            if (this.files.length > 1000) {
                this.showSnackbar('Please select only up to 1000 files', false, 'invalid', 'fas fa-check-circle');
                return;
            }

            let formData = new FormData();
            for( var i = 0; i < this.files.length; i++ ){
                formData.append('files[' + i + ']', this.files[i]);
            }

            this.processing = true;
            this.showSnackbar('Processing', true);
            let header = JSON.parse(JSON.parse(localStorage.getItem("lawfirm")).headers);
            header.headers['Content-Type'] = 'multipart/form-data';
            formData.append('case_id', this.current_case);
            axios.post(
                    `${process.env.VUE_APP_URL}/frozen/compile`,
                    formData,
                    {
                        headers: header.headers,
                        onUploadProgress: function( progressEvent ) {
                            this.progress = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ));
                        }.bind(this)
                    }
            ).then(response => {
                this.processing = false;
                Object.assign(this.response, response.data.data);
                this.progress = 0;
                this.files = null;
                document.getElementById('frozenAccountSourceInput').value = '';

                if (response.data.data.has_unprocessed_zip) {
                    this.showSnackbar('One or more zip file(s) is/are password protected. Please select another file', false, 'invalid', 'fas fa-check-circle');
                }
                else {
                    this.showSnackbar('Done', false, 'success', 'fas fa-check-circle');
                }
            })
            .catch(ex => {
                this.processing = false;
                this.showSnackbar('Something went wrong. Please contact system admin', false, 'invalid', 'fas fa-check-circle');
                console.log(ex);
            });
        },
        downloadErrorsLog() {
            window.open(`${process.env.VUE_APP_URL}/frozen-account/error-log?filename=${encodeURIComponent(this.response.error)}`, '_blank');
        },
        downloadExcel() {
            window.open(`${process.env.VUE_APP_URL}/frozen-account/excel?filename=${encodeURIComponent(this.response.result)}`, '_blank');
        },
        showSnackbar(text, loading, type, icon) {
            if (this.$parent.$parent.showSnackbar) {
                this.$parent.$parent.showSnackbar(text, loading, type, icon);
            }
            else {
                this.$parent.showSnackbar(text, loading, type, icon);
            }
        }
    }
}
</script>

<style scoped>
    .a-container {
        position: relative;
        width: 100%;
        min-height: 100vh;
    }
    
    .add-new-con {
        position: relative;
        text-align: left;
        padding: 20px 334px 10px 0;
    }

    .add-new-con h5 {
        font-weight: 500 !important;
        color: #515365;
        line-height: 24px;
        font-size: 24px;
        margin-bottom: 17px;
    }

    .add-new-con .photo-modal {
        position: absolute;
        right: -18px;
        top: 55%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .main-con {
        padding: 70px 0 60px 81px;
        position: absolute;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 50%;
        top: 50%;
    }
    .a-header {
        letter-spacing: 0.024em;
        color: #383A3D;
        font-weight: 400;
        font-size: 24px;
        margin-bottom: 17px;
        line-height: 32px;
        max-width: 100%;
        width: 374px;
    }
    .a-header span {
        display: block;
        font-size: 16px;
        font-weight: 400;
        color: #383A3D;
        margin-top: 8px;
    }
    .a-sub-header {
        font-size: 20px;
        color: #515365;
        font-weight: 500;
        margin-bottom: 29px;
    }
    .manual-add-splash {
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        position: absolute;
        right: 150px;
        width: 29%;
        top: 43%;
    }
    .manual-add-back {
        position: absolute;
        bottom: 40px;
        right: 40px;
        z-index: 2;
    }
    .reminder-card {
        margin-top: 35px;
        height: 140px;
        display:block;
        width: 356px;
    }
    .splat-background { 
        position: absolute;
        z-index : -1;
        width   : 54.2%;
        right   : 0;
        top     : 0;
    }
    .a-modal-seller-info-item {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        padding: 15px 20px 13px 67px;
        word-break: break-all;
        letter-spacing: 0.25px;
        margin-bottom: 10px;
        border-radius: 4px;
        position: relative;
        text-align: left;
        color:#2D599E;
        font-size: 14px;
        min-height:68px;
        opacity: 1;
    }
    .a-modal-seller-info-item h5 {
        letter-spacing: 0.24px;
        font-weight: 500;
        color:#2D599E;
        font-size: 14px;
        margin:0;
    }
    .a-modal-seller-info-item h5 span {
        letter-spacing: 0;
        font-weight: 400;
        margin-top: 5px;
        font-size: 12px;
        display: block;
    }
    .a-modal-seller-info-item i, .a-modal-seller-info-item img {
        -webkit-transform:translateY(-50%);
        transform: translateY(-50%);
        position:absolute;
        font-size: 32px;
        left:15px;
        top:47%;
    }
    .a-modal-seller-info-item img {
        width: 24px;
    }
</style>