<template>
    <ar-dialog 
        :id="id"
        :custom_width="view === 'list_seller' ? '100%' : '800px'"
        xx-large
        :has_button="false"
        :has_footer="false"
    >
        <template>
            <ar-upload
                v-if="view === 'upload'"
                v-model="listings"
                :caseID="caseID"
                @searchByUpdate="mappedColumns.platform_account_id = $event.target.value"
                @next="view = 'list_seller'"
                @close="close()"
            />
            <ar-seller-list
                v-else-if="view === 'list_seller'"
                :listings="mappedListings"
                @next="view = 'list_column'"
                @close="close()"
                @refresh="$emit('refresh')"
            />
            <ar-column-list
                v-else-if="view === 'list_column'"
                v-model="mappedColumns" :columns="columns"
                @next="view = 'list_seller'"
            />
        </template>
    </ar-dialog>
</template>

<script>
import Vue from 'vue';
import ArUpload from './ModalImportEbayUpload'
import ArSellerList from './ModalImportEbayListSeller'
import ArColumnList from './ModalImportEbayListColumn'

export default Vue.extend({
    name: 'ar-modal-import-ebay',
    components: {
        ArDialog: () => import('../../layouts/Dialog'),
        ArUpload,
        ArSellerList,
        ArColumnList
    },
    props: {
        id: {
            type: String,
            default: 'import-ebay-sales-history'
        }
    },
    data() {
        return {
            view: 'upload', // upload | list_seller | list_column
            mappedColumns: {
                platform_account_id: null,
                gross: null,
                items_sold: null,
                available_items: null
            },
            listings: []
        }
    },
    mounted() {
        setTimeout(() => $(`#${this.id}`).on('show.bs.modal', this.initializeVariables), 200);//added timeout to solve mounting error
    },
    computed: {
        columns() {
            if (this.listings.length === 0) {
                return [];
            }

            return Object.keys(this.listings[0].sheet_data);
        },
        mappedListings() {
            let keys = [];
            let result = [];
            this.listings.forEach(l => {
                let platform_account_id = l.sheet_data[this.mappedColumns.platform_account_id];

                if (!keys.includes(platform_account_id)) {
                    result.push({
                        ...l,
                        platform_account_id: platform_account_id,
                        gross: 0,
                        items_sold: 0,
                        available_items: 0
                    });
                    keys.push(platform_account_id);
                }

                let resultIdx = keys.indexOf(platform_account_id);
                result[resultIdx].gross += l.sheet_data[this.mappedColumns.gross];
                result[resultIdx].items_sold += l.sheet_data[this.mappedColumns.items_sold];
                result[resultIdx].available_items += l.sheet_data[this.mappedColumns.available_items];
            });

            return result;
        },
        caseID() {
            return this.$route.params.case_id || 0;
        }
    },
    methods:{
        getDefaultColumnMapping() {
            return {
                platform_account_id: 'seller_id',
                gross: 'gmv_usd',
                items_sold: 'qty_sold',
                available_items: 'qty_avail'
            };
        },
        close() {
            $(`#${this.id}`).modal('hide');
            this.view = null;//to re-initialize variables from child components
        },
        initializeVariables() {
            this.view = 'upload';
            this.listings = [];
            this.mappedColumns = Object.assign({}, this.getDefaultColumnMapping());
        }
    }
});
</script>